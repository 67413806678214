import React from 'react';
import Layout from '../components/layout/layout';

import { PRIMARY_TEXT_COLOR } from '../constants';
import { Wrapper } from '../components/shared/wrapper';
import { Fullscreen } from '../components/fullscreen/fullscreen';

import { TitleContainer, TitleText } from '../components/shared';
import Container from 'reactstrap/es/Container';
import { TextBlock } from '../components/shared/text-block';

import { CURRENT_YEAR } from '../utils/date.utils';

import fb from '../../static/images/facebook_settings_image.png';

export default () => {
    return (
        <Layout>
            <Fullscreen height="84vh" className="d-flex justify-content-center flex-column position-relative">
                <TitleContainer>
                    <TitleText>Cookies</TitleText>
                </TitleContainer>
            </Fullscreen>
            <Wrapper color={PRIMARY_TEXT_COLOR}>
                <Container>
                    <TextBlock title={'Overview'}>
                        <p>
                            Equaleyes d.o.o. (also referred to as “Equaleyes”, “we”, “our” and “us”) use cookies on our site (
                            <a href="https://equaleyes.com/" rel={'nofollow'}>
                                https://equaleyes.com/
                            </a>
                            ) for a number of purposes. They help us to provide you with a good experience when you browse our website, and
                            also allow us to improve our site. By continuing to browse the site, you are agreeing to our use of cookies.
                        </p>

                        <p>
                            A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer
                            if you agree. Cookies contain information that is transferred to your computer’s hard drive.
                        </p>

                        <p>
                            There are two types of cookies: session cookies and persistent cookies. Session cookies are created temporarily
                            in your browser's subfolder while you are visiting a website. Once you leave the site, the session cookie is
                            deleted. On the other hand, persistent cookie files remain in your browser's subfolder and are activated again
                            once you visit the website that created that particular cookie. A persistent cookie remains in the browser's
                            subfolder for the duration period set within the cookie's file.
                        </p>

                        <p>
                            A cookie will typically contain the name of the domain from which the cookie has come, the "lifetime" of the
                            cookie, and a value, usually a randomly generated unique number. Two common types of cookies are used on most
                            websites-session cookies, which are temporary cookies that remain in the cookie file of your browser until you
                            leave the site, and persistent cookies, which remain in the cookie file of your browser for much longer (though
                            how long will depend on the lifetime of the specific cookie).
                        </p>

                        <p>
                            You can find more information about the individual cookies we use and the purposes for which we use them below.
                        </p>

                        <p>
                            Our websites sets several types of third-party cookie, and we do not control the operation of any of them. The
                            third-party cookies which may be set include:
                        </p>
                    </TextBlock>
                    <TextBlock title="Our cookies">
                        <p>
                            This website will set some cookies that are essential for the website to operate correctly. These cookies, none
                            of which capture personally identifiable information, are as follows
                        </p>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Cookie name</th>
                                    <th>Webpage</th>
                                    <th>Cookie ID</th>
                                    <th>Source</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>accept_cookies</td>
                                    <td>
                                        <a href="https://equaleyes.com/" rel={'nofollow'}>
                                            equaleyes.com
                                        </a>
                                    </td>
                                    <td>General</td>
                                    <td>Equaleyes Solutions Ltd.</td>
                                    <td>Accept cookie stores it the website user hasaccepted our Cookie toast.</td>
                                </tr>
                            </tbody>
                        </table>
                    </TextBlock>
                    <TextBlock title="Google Analytics">
                        <p>
                            We use Google Analytics to collect data about website usage. This data does not include personally identifiable
                            information.
                        </p>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Cookie name</th>
                                    <th>Webpage</th>
                                    <th>Cookie ID</th>
                                    <th>Source</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>_ga</td>
                                    <td>
                                        <a href="https://equaleyes.com/" rel={'nofollow'}>
                                            equaleyes.com
                                        </a>
                                    </td>
                                    <td>GA</td>
                                    <td>Google analytics</td>
                                    <td>_ga is used to distinguish users and has an expiration of 2-years.</td>
                                </tr>
                                <tr>
                                    <td>_gat</td>
                                    <td>
                                        <a href="https://equaleyes.com/" rel={'nofollow'}>
                                            equaleyes.com
                                        </a>
                                    </td>
                                    <td>GA</td>
                                    <td>Google analytics</td>
                                    <td>_gat is used to throttle requests and has an expiration of 10-minutes.</td>
                                </tr>
                                <tr>
                                    <td>_gid</td>
                                    <td>
                                        <a href="https://equaleyes.com/" rel={'nofollow'}>
                                            equaleyes.com
                                        </a>
                                    </td>
                                    <td>GA</td>
                                    <td>Google analytics</td>
                                    <td>_gid is used to distinguish users and has an expiration of 24-hours.</td>
                                </tr>
                                <tr>
                                    <td>__utma</td>
                                    <td>
                                        <a href="https://equaleyes.com/" rel={'nofollow'}>
                                            equaleyes.com
                                        </a>
                                    </td>
                                    <td>GA</td>
                                    <td>Google analytics</td>
                                    <td>
                                        <a
                                            href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage#gajs"
                                            rel={'nofollow'}
                                        >
                                            View more
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>__utma</td>
                                    <td>
                                        <a href="https://equaleyes.com/" rel={'nofollow'}>
                                            equaleyes.com
                                        </a>
                                    </td>
                                    <td>GA</td>
                                    <td>Google analytics</td>
                                    <td>
                                        <a
                                            href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage#gajs"
                                            rel={'nofollow'}
                                        >
                                            View more
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>__utmc</td>
                                    <td>
                                        <a href="https://equaleyes.com/" rel={'nofollow'}>
                                            equaleyes.com
                                        </a>
                                    </td>
                                    <td>GA</td>
                                    <td>Google analytics</td>
                                    <td>
                                        <a
                                            href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage#gajs"
                                            rel={'nofollow'}
                                        >
                                            View more
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>__utmz</td>
                                    <td>
                                        <a href="https://equaleyes.com/" rel={'nofollow'}>
                                            equaleyes.com
                                        </a>
                                    </td>
                                    <td>GA</td>
                                    <td>Google analytics</td>
                                    <td>
                                        <a
                                            href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage#gajs"
                                            rel={'nofollow'}
                                        >
                                            View more
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <small>Source: Equaleyes, {CURRENT_YEAR}.</small>
                        <br />
                        <p>
                            You can view the Google Privacy Policy here
                            <br />
                            You can also read more about option for Google Analytics Opt Out{' '}
                            <a href="https://tools.google.com/dlpage/gaoptout?hl=en" rel={'nofollow'}>
                                here
                            </a>
                            .
                        </p>
                    </TextBlock>
                    <TextBlock title="HotJar">
                        <p>
                            Is a service that allows us to analyze the online behaviour of website users. This is achieved by combining both
                            analysis tools and feedback tools.
                        </p>
                        <p>
                            Hotjar allows operators of a Hotjar Enabled Site to collect and process information which is automatically and
                            passively collected, whilst you navigate through and interact with the content on a Hotjar Enabled Site,
                            together with information on your device or computer (such as cookies). The sole purpose of passively collecting
                            your information is to improve your experience when using a Hotjar Enabled Site.
                        </p>
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th>Cookie name</th>
                                    <th>Webpage</th>
                                    <th>Cookie ID</th>
                                    <th>Source</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>_hjIncludedInSample</td>
                                    <td>
                                        <a href="https://equaleyes.com" rel={'nofollow'}>
                                            equaleyes.com
                                        </a>
                                    </td>
                                    <td>HotJar</td>
                                    <td>
                                        <a href="https://www.hotjar.com" rel={'nofollow'}>
                                            hotjar.com
                                        </a>
                                    </td>
                                    <td>Did user accept to be tracked</td>
                                </tr>
                            </tbody>
                        </table>
                        <small>Source: Source: Equaleyes, {CURRENT_YEAR}.</small>
                        <br />
                        <p>
                            You can view the Hotjar privacy policy{' '}
                            <a href="https://www.hotjar.com/privacy" rel={'nofollow'}>
                                here
                            </a>{' '}
                            or Opt Out from Hotjar{' '}
                            <a href="https://www.hotjar.com/opt-out" rel={'nofollow'}>
                                here
                            </a>
                            .
                        </p>
                    </TextBlock>
                    <TextBlock title="Facebook pixel">
                        <p>
                            The Facebook pixel is an analytics tool that allows you to measure the effectiveness of your advertising by
                            understanding the actions people take on your website. We can use pixel data to
                        </p>
                        <ul>
                            <li>Make sure our ads are being shown to the right people</li>
                            <li>Build advertising audiences</li>
                            <li>Unlock additional Facebook advertising tools</li>
                        </ul>

                        <p className="margin-top-sm">
                            Read more about the Facebook Pixel{' '}
                            <a href="https://www.facebook.com/business/help/952192354843755" rel={'nofollow'}>
                                here
                            </a>
                            .
                        </p>
                        <p className="margin-top-lg">
                            <strong>Opt out option for Facebook pixel:</strong>
                        </p>
                        <p>
                            To opt out of Facebook showing you targeted ads from other sites, or from seeing Facebook ads on other sites,
                            open your Facebook page and click the upside-down triangle in the upper right corner. Select "Settings" and then
                            in the left-hand column select "Ads."
                        </p>
                        <p>
                            {' '}
                            You can also check other details to opt-out from tracking data from Facebook{' '}
                            <a href="https://www.imore.com/how-opt-out-facebook-data-sharing" rel={'nofollow'}>
                                here
                            </a>
                            .
                        </p>
                        <p className="margin-top-lg">
                            <img src={fb} width="200" alt="" className="d-block" />
                            <small>Source: Facebook, {CURRENT_YEAR}.</small>
                        </p>
                    </TextBlock>
                </Container>
            </Wrapper>
        </Layout>
    );
};
